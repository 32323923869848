import React, { useEffect } from "react"

import Layout from "../layout/layout"
import SEO from "../components/seo"

import TermsOfUse from "../components/TermsOfUse"
import TermsData from "../DB/terms-of-use"
import { AnalyticsEventCategories, emitAnalyticsEvent } from "../analytics"

const IndexPage = () => {
    useEffect(() => {
        emitAnalyticsEvent("Page view", AnalyticsEventCategories.TERMS)
    }, [])

    return (
        <Layout>
            <SEO title="Terms" />
            <TermsOfUse data={TermsData} />
        </Layout>
    )
}

export default IndexPage
