import React from "react"
import { Link } from "gatsby"
import styles from "./styles.module.scss"

export default function TermsOfUse() {
    return (
        <section className={styles.privacy}>
            <div className={styles.privacy__container}>
                <h1 className={styles.privacy__title}>Terms of Service</h1>
                <h2 className={styles.privacy__subtitle}>Thank you for using Sync.AI Services!</h2>
                <div className={styles.privacy__main}>
                    <p className={styles.c1} style={{ textAlign: "center", marginBottom: "2rem" }}>
                        <span>Last updated by November 12, 2020</span>
                    </p>
                    <p className={styles.c1}>
                        By using any of the Sync.AI apps, products, software, websites, or services
                        made
                        available by Sync.AI, including the Sync.AI Meetings app and the Sync.AI
                        Calls app
                        (collectively referred to as <span className={styles.bold}>“Sync.AI Services”</span>),
                        provided by Sync.Me Ltd. (<span className={styles.bold}>“Sync.AI”</span>,
                        <span className={styles.bold}>“we”</span>, <span
                        className={styles.bold}>“our”</span> or <span
                        className={styles.bold}>“us”</span>), you
                        agree to be bound by the following terms and conditions
                        (<span className={styles.bold}>“Agreement”</span>). If you do not agree to
                        this
                        Agreement, you may not access or use the
                        Sync.AI Services.
                    </p>
                    <p className={styles.c1}>
                        Notwithstanding anything to the contrary, to the extent that consumer
                        protection or
                        local laws or regulations grant you mandatory or statutory rights, this
                        Agreement shall
                        only limit such rights to the maximum extent permitted by such laws or
                        regulations.
                    </p>
                    <p className={styles.c1}>
                        Violation of any of the terms below can result in the termination of your
                        user-account
                        or access to Sync.AI Services. Violation of any of the terms could also
                        terminate your
                        usage rights of Sync.AI Services. If a provision of this Agreement is or
                        becomes
                        illegal, invalid or unenforceable in any jurisdiction, that shall not
                        affect: (i) the
                        validity or enforceability in that jurisdiction of any other provision of
                        this
                        agreement; or (ii) the validity or enforceability in other jurisdictions of
                        that or any
                        other provision of this Agreement.
                    </p>
                    <ol style={{paddingLeft: '2rem'}}>
                        <li>
                            <span className={styles.bold}>&nbsp;ABILITY TO ACCEPT.</span>
                            <p className={`${styles.c1}`}>
                                No one under 18 is allowed to create an account or use the Sync.AI
                                Services. By
                                installing the Sync.AI Services you affirm that you are over eighteen
                                (18) years of
                                age.
                            </p>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;LICENSE.</span>
                            <p className={`${styles.c1}`}>
                                Subject to your full compliance with all of the provisions of this
                                Agreement, Sync.AI
                                hereby grants you a non-exclusive, non-sub-licensable, non-assignable
                                non-transferable,
                                revocable license to use a compiled code copy of Sync.AI Services
                                account on one
                                mobile
                                device, owned, rented or leased solely by you. You acknowledge that
                                Sync.AI may from
                                time to time issue upgraded versions of its software, and may
                                automatically upgrade
                                the
                                version of such software that you are using on your device. You consent
                                to such
                                automatic upgrading on your mobile device, and agree that the terms and
                                conditions of
                                these Terms will apply to all such upgrades. You agree that, to the
                                maximum extent
                                permitted by law, Sync.AI will not be liable to you for any such
                                modifications. The
                                foregoing license grant under this Agreement is not a sale of Sync.AI
                                Services or
                                Sync.AI's software or any copy thereof and Sync.AI or its third party
                                licensors or
                                partners retain all right, title, and interest in the Sync.AI Services
                                (and any copy
                                thereof). Any attempt by you to transfer any of the rights, duties or
                                obligations
                                hereunder, except as expressly provided for in this Agreement, is void.
                                Sync.AI
                                reserves
                                all rights not expressly granted under this Agreement. We are entitled,
                                without any
                                liability, to refuse, restrict, limit, suspend and/or interfere or
                                interrupt the
                                Sync.AI
                                Services or any part thereof, without any notice to you for the repair,
                                improvement,
                                and/or upgrade of the Sync.AI Services or for any of the reasons for
                                termination as
                                mentioned below.
                            </p>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;LICENSE RESTRICTIONS</span>
                            <ol>
                                <li className={styles.li_c}>
                                    &nbsp;You hereby represent and warrant
                                    that you will not,
                                    and shall not permit any third party to:
                                    <ul className={styles.roman}>
                                        <li>
                                            <p className={`${styles.c1}`}>engage in any activity
                                                that interferes with or
                                                disrupts Sync.AI Services (or the servers and
                                                networks which are connected to
                                                Sync.AI Services).You agree that you will not
                                                reproduce, duplicate, copy,
                                                sell, trade attempt to derive the source code of,
                                                create derivative works of,
                                                distribute, or resell Sync.AI Services, or any part
                                                thereof, for any purpose
                                                without the express written permission by
                                                Sync.AI.</p>
                                        </li>
                                        <li>
                                            <p className={`${styles.c1}`}>Remove, circumvent,
                                                disable, damage or
                                                otherwise interfere with any features of the Sync.AI
                                                Services, or attempt to
                                                gain unauthorized access to any portion of the
                                                Sync.AI Services through any
                                                means, or interfere with, corrupt, or disrupt the
                                                operation or performance of
                                                the Sync.AI Services or the ability of any other
                                                person to use the Sync.AI
                                                Services, including, without limitation,
                                                transmitting content through the
                                                Sync.AI Services that contain any viruses, worms,
                                                Trojan horses, or other
                                                destructive items or harmful codes, or placing
                                                excessive load on the servers,
                                                or attempting to degrade the performance of the
                                                networks or servers connected
                                                to the Sync.AI Services or any part thereof in any
                                                way;</p>
                                        </li>
                                        <li>
                                            <p className={`${styles.c1}`}>Circumvent, disable or
                                                otherwise interfere
                                                with security-related features of the Sync.AI
                                                Services or any part thereof or
                                                features that prevent or restrict use or copying of
                                                any Materials (as defined
                                                below) or that enforce limitations on use of the
                                                Service or any part
                                                thereof; </p>
                                        </li>
                                        <li>
                                            <p className={`${styles.c1}`}>Make the App available
                                                over a network where
                                                it could be used by multiple devices owned or
                                                operated by different people at
                                                the same time;</p>
                                        </li>
                                        <li>
                                            <p className={`${styles.c1}`}>Violate other users’ or
                                                third parties’ rights
                                                to privacy, publicity and other rights, or harvest,
                                                scrap, data aggregate,
                                                data mine, screen scrap, index or collect data and
                                                information about other
                                                Users or third parties without their consent,
                                                whether manually, or
                                                automatically with the use of any means, including
                                                without limitation Bots,
                                                Crawlers, Spiders, Robots, Sifters and Load Testers,
                                                without the express
                                                written consent of Sync.AI, or engage in testing,
                                                pen-testing, sniffing or
                                                monitoring of the Sync.AI Services, its systems,
                                                software or hardware in any
                                                way; </p>
                                        </li>
                                        <li>
                                            <p className={`${styles.c1}`}>Remove, alter or obscure
                                                any proprietary
                                                notice or identification, including copyright,
                                                trademark, patent or other
                                                notices, contained in or displayed on or via the
                                                Sync.AI Services;</p>
                                        </li>
                                        <li>
                                            <p className={`${styles.c1}`}>Use the Sync.AI Service in
                                                connection with
                                                any advertisements, solicitations, chain letters,
                                                pyramid schemes, spamming or
                                                any other commercial communication; </p>
                                        </li>
                                        <li>
                                            <p className={`${styles.c1}`}>Use the Sync.AI Service in
                                                connection with
                                                material which a reasonable person could deem to be:
                                                stalking, offensive,
                                                inaccurate, incomplete, abusive, obscene,
                                                objectionable, defamatory, libelous,
                                                fraudulent or deceptive, indecent, pornographic,
                                                profane, threatening,
                                                advocating harassment or intimidation, distressing,
                                                vulgar, hateful,
                                                malicious, harmful for minors, racially or
                                                ethnically offensive, advocating
                                                racism, bigotry, hatred or physical harm of any kind
                                                against any group or
                                                individual, or disparaging the religious, political,
                                                or legal agenda of any
                                                person or entity;</p>
                                        </li>
                                        <li>
                                            <p className={`${styles.c1}`}>Use the Sync.AI name, logo
                                                or trademarks
                                                without our prior written consent;</p>
                                        </li>
                                        <li>
                                            <p className={`${styles.c1}`}>Use the Sync.AI Service in
                                                connection with
                                                material which promotes illegal activities, or the
                                                violation of any local,
                                                state, national, or international law or regulation,
                                                including, without
                                                limitation, laws governing intellectual property and
                                                other proprietary rights
                                                (including, but not limited to, patents, trademarks,
                                                trade secrets,
                                                copyrights, or any confidential, proprietary or
                                                trade secret information of
                                                any third party), information protection and
                                                privacy, including, but not
                                                limited to, content which disseminates another
                                                person’s personal information
                                                without his or her permission; and/or</p>
                                        </li>
                                        <li>
                                            <p className={`${styles.c1}`}>use any part of the
                                                Sync.AI Service to
                                                violate any applicable laws, rules or regulations,
                                                or for any unlawful,
                                                harmful, irresponsible, or inappropriate purpose, or
                                                in any manner that
                                                breaches this Agreement.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li className={styles.li_c}>
                                    &nbsp;You agree that you are solely responsible for (and that
                                    Sync.AI has no
                                    responsibility to you or to any third party for) any breach
                                    of your obligations
                                    under the Agreement and for the consequences (including any
                                    loss or damage which
                                    Sync.AI may suffer) of any such breach.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;YOUR USE OF SYNC.AI SERVICES</span>
                            <ol>
                                <li>
                                    You agree to use Sync.AI Services only for purposes that are
                                    permitted by:
                                    (i) the Agreement and (ii) any applicable law, regulation or
                                    generally accepted
                                    practices or guidelines in the relevant jurisdictions.
                                </li>
                                <li>
                                    Sync.AI may offer or require you to register for a user-account.
                                    You must
                                    ensure that all registration information you provide will always
                                    be valid,
                                    correct, and up to date. You are responsible for maintaining the
                                    security of your
                                    user-account and password. User-accounts registered by bots or
                                    other automated
                                    methods are not permitted. You agree that you will not
                                    impersonate another person.
                                    As between you and Sync.AI, you are solely responsible and
                                    liable for the activity
                                    that occurs in connection with your user-account.
                                </li>
                                <li>
                                    To the maximum extent permitted by law, Sync.AI reserves the
                                    right (but has no
                                    obligation) to pre-screen, review, flag, filter, modify, refuse
                                    or remove any or
                                    all content or user-account from Sync.AI Services.
                                </li>
                                <li>
                                    You agree that you will not post or upload information or
                                    material to Sync.AI
                                    Services which:
                                    <ul className={styles.nested}>
                                        <li>
                                            <p className={styles.c1}>
                                                a reasonable person or user could deem to be:
                                                offensive, abusive, obscene,
                                                objectionable, defamatory, libelous, sexually
                                                explicit, threatening,
                                                advocating harassment or intimidation, racially or
                                                ethnically offensive or
                                                otherwise offensive to any group or individual, or
                                                promoting hatred or
                                                physical harm of any kind.
                                            </p>
                                        </li>
                                        <li>
                                            <p className={styles.c1}>
                                                promotes information that is false or misleading;
                                            </p>
                                        </li>
                                        <li>
                                            <p className={styles.c1}>
                                                promotes illegal activities;
                                            </p>
                                        </li>
                                        <li>
                                            <p className={styles.c1}>
                                                impersonates, or otherwise misrepresents
                                                affiliation, connection or
                                                association with any person or entity.
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    You understand that by using Sync.AI Services you may be
                                    exposed to content
                                    that you may find offensive, indecent or objectionable and that,
                                    in this respect,
                                    you use Sync.AI Services at your own risk.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;MINORS</span>
                            <p className={`${styles.c1} ${styles.underline}`}>
                                You may use the Services only if you are at least sixteen (16) years
                                old. If you are
                                under the age of eighteen (18) or under the legal age to form a binding
                                contract in
                                the
                                jurisdiction in which you are located, you may only use the Sync.AI’s
                                Services under
                                the
                                supervision of a parent or legal guardian who has agreed to stand behind
                                any action
                                you
                                take and/or agreement you enter into while using the Sync.AI’s Services,
                                including
                                this
                                Agreement. We reserve the right to require you at any stage to provide
                                proof of age,
                                and, if applicable, approval of your use of the Sync.AI’s Services by
                                your parent or
                                legal guardian, so that we can verify that only eligible Users are using
                                the Sync.AI’s
                                Services. In the event that it comes to our knowledge that a person
                                under the age of
                                sixteen (16) is using the Sync.AI’s Services, we may prohibit and block
                                such User from
                                using the Services
                            </p>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;THIRD PARTY SOURCE AND CONTENT</span>
                            <ol>
                                <li>
                                    You understand that Sync.AI can use third parties (e.g., hosting
                                    partners) to
                                    provide the necessary resources (e.g., hardware, software,
                                    networking, storage,
                                    etc.) to run Sync.AI Services. To the maximum extent permitted
                                    by law, Sync.AI may
                                    stop, remove, modify, or add (permanently or temporarily)
                                    Sync.AI Services (or
                                    features within Sync.AI Services) at Sync.AI’s sole discretion.
                                    Any new, changed,
                                    or removed features are subject to this Agreement. To the
                                    maximum extent permitted
                                    by law, continued use of Sync.AI Services after any such changes
                                    constitute your
                                    consent to the changes.
                                </li>
                                <li>
                                    With respect to any open source or third-party code that may be
                                    incorporated
                                    in Sync.AI Services, such open source code is covered by the
                                    applicable open
                                    source or third-party license, if any, authorizing use of such
                                    code. If you want
                                    to receive more information about this section, please send us
                                    an email to &nbsp;
                                    <a href={"mailto:support@sync.ai"} target={"_blank"}>
                                        support@sync.ai
                                    </a>. Nothing in this Agreement limits your rights under, or
                                    grants you
                                    rights that supersede, the terms and conditions of any
                                    applicable end user license
                                    for such open source software.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;INTELLECTUAL PROPERTY RIGHTS</span>
                            <ol>
                                <li>
                                    The Sync.AI Services is licensed and not sold to you under this
                                    Agreement and
                                    you acknowledge that Sync.AI retain all right, title, interest,
                                    ownership rights
                                    and Intellectual Property Rights in and to the Sync.AI Services
                                    (and its related
                                    software). We reserve all rights not expressly granted herein to
                                    the App.
                                    <span className={styles.bold}>&nbsp;“Intellectual Property Rights”</span> means any and all rights, titles
                                    and interests in
                                    and to any and all trade secrets, patents, copyrights, service
                                    marks, trademarks,
                                    know-how, or similar intellectual property rights, as well as
                                    any and all moral
                                    rights, rights of privacy, publicity and similar rights of any
                                    type under the laws
                                    or regulations of any governmental, regulatory, or judicial
                                    authority, whether foreign or domestic.
                                </li>
                                <li>
                                    All content, visual interfaces, information, graphics, design,
                                    compilation,
                                    computer code, products, software, trademarks, service marks,
                                    trade names, and
                                    other Intellectual Property Rights related to the Sync.AI
                                    Services, including, but
                                    not limited to, the mobile device applications, the information
                                    provided in the
                                    Sync.AI services and all other elements contained in Sync.AI
                                    Services
                                    (collectively, the <span className={styles.bold}>“Materials”</span>) are the property of Sync.AI or
                                    its subsidiaries or
                                    affiliated companies and/or third-party licensors. This
                                    Agreement does not convey
                                    to you any interest in or to the Materials, but only a limited,
                                    revocable right of
                                    use in accordance with this Agreement and you acknowledge and
                                    agree that except
                                    for such limited right, under no circumstances do you acquire
                                    any right, title or
                                    interest to any part of the Materials, or any related materials.
                                    “Sync.AI” and the
                                    Sync.AI logo are marks of Sync.AI and its affiliates. All other
                                    marks used on the
                                    Sync.AI Services are the trademarks, service marks, or logos of
                                    their respective
                                    owners. We reserve all rights not expressly granted in and to
                                    the Service and
                                    Materials. Materials are provided to you for your information
                                    and personal use
                                    only. If you download or print a copy of the Materials you must
                                    retain all
                                    copyright and other proprietary notices contained therein.
                                </li>
                                <li>
                                    If you send us or otherwise share with us any ideas, comments,
                                    feedback or
                                    suggestions regarding the Materials, you acknowledge and agree
                                    that we may use
                                    such ideas, comments or suggestions in elany manner, for any
                                    purpose, and without
                                    the need for any further compensation, approval or consent.
                                </li>
                                <li>
                                    Failure to exercise or enforce any right or provision of the
                                    Agreement shall
                                    not constitute a waiver of such right or provision for either
                                    party. You agree
                                    that Sync.AI owns all legal rights and intellectual property
                                    rights in Sync.AI
                                    Services. You must not remove, modify or obscure any legal
                                    notices. You are not
                                    entitled to use Sync.AIs’ trademarks, trade names, brands,
                                    domain names, or other
                                    distinctive brand features without Sync.AI's prior written
                                    consent.
                                </li>
                                <li>
                                    Under no circumstances will Sync.AI or its affiliates be
                                    responsible or
                                    liable for any loss or damage resulting from your reliance on
                                    information or other
                                    content posted in Sync.AI Services, or transmitted to or by any
                                    users. Any advice,
                                    information or other content that may be posted in Sync.AI
                                    Services is entirely
                                    for entertainment purposes only. Sync.AI makes no
                                    representations or warranties
                                    and expressly disclaims any and all liability concerning any
                                    treatment, action by,
                                    or effect on any person following the information offered or
                                    provided within or
                                    through Sync.AI Services.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;WARRANTY DISCLAIMERS</span>
                            <ol>
                                <li>
                                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU UNDERSTAND AND AGREE
                                    THAT YOUR
                                    USE OF SYNC.AI SERVICES IS AT YOUR SOLE RISK. SYNC.AI SERVICES
                                    ARE PROVIDED ON AN
                                    “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND
                                    EITHER EXPRESS OR
                                    IMPLIED INCLUDING, WITHOUT LIMITATION, REPRESENTATIONS,
                                    WARRANTIES AND CONDITIONS
                                    OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                                    NON-INFRINGEMENT, AND
                                    THOSE ARISING BY STATUTE OR FROM A COURSE OF DEALING OR USAGE OF
                                    TRADE. SYNC.AI
                                    DOES NOT WARRANT THAT (I) SYNC.AI SERVICES WILL MEET YOUR
                                    REQUIREMENTS OR
                                    EXPECTATIONS, (II) SYNC.AI SERVICES WILL BE DELIVERED
                                    UNINTERRUPTED, TIMELY,
                                    SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED
                                    FROM THE USE OF
                                    SYNC.AI SERVICES WILL BE ACCURATE OR RELIABLE, (IV) ANY ERRORS
                                    IN SYNC.AI SERVICES
                                    WILL BE CORRECTED.
                                </li>
                                <li>
                                    WE DO NOT WARRANT THAT THE SYNC.AI SERVICES WILL OPERATE
                                    ERROR-FREE, THAT THE
                                    APP IS FREE OF VIRUSES OR OTHER HARMFUL CODE OR THAT WE WILL
                                    CORRECT ANY ERRORS IN
                                    THE SYNC.AI SERVICES. YOU AGREE THAT WE WILL NOT BE HELD
                                    RESPONSIBLE OR LIABLE FOR
                                    THIRD PARTY CONTENT, THIRD PARTY SOURCES, THIRD PARTY OPEN
                                    SOURCE SOFTWARE, YOUR
                                    DATA, PERSONAL INFORMATION, AND ANY CONSEQUENCES TO YOU OR ANY
                                    THIRD PARTY THAT
                                    MAY RESULT FROM USE OR INABILITY TO USE ANY OF THE ABOVE
                                    INCLUDING TECHNICAL
                                    PROBLEMS INCLUDING WITHOUT LIMITATION IN CONNECTION WITH THE
                                    INTERNET (SUCH AS
                                    SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER
                                    SERVERS) OR ANY
                                    TELECOMMUNICATIONS OR INTERNET PROVIDERS.
                                </li>
                                <li>
                                    IF YOU HAVE A DISPUTE WITH ANY OTHER APP USER, YOU AGREE THAT WE
                                    ARE NOT
                                    LIABLE FOR ANY CLAIMS OR DAMAGES ARISING OUT OF OR CONNECTED
                                    WITH SUCH A DISPUTE.
                                    WE RESERVE THE RIGHT, BUT HAVE NO OBLIGATION, TO MONITOR ANY
                                    SUCH DISPUTE.
                                </li>
                                <li>
                                    To the maximum extent permitted by law, you understand and agree
                                    that Sync.AI
                                    shall not be liable for any direct, indirect, incidental,
                                    special, consequential
                                    or exemplary damages, including but not limited to any loss of
                                    profit, loss of
                                    goodwill, loss of business reputation, loss of data, cost of
                                    procurement of
                                    substitute goods or Sync.AI Services, or other intangible loss,
                                    resulting from:
                                    (i) the use or the inability to use Sync.AI Services; (ii) any
                                    changes which
                                    Sync.AI may make to Sync.AI Services, or any permanent or
                                    temporary cessation in
                                    the provision of Sync.AI Services; (iii) unauthorized access to
                                    or alteration of
                                    your transmissions or data; (iv) the deletion of, corruption of,
                                    or failure to
                                    store, any content and other communications data maintained or
                                    transmitted by or
                                    through the use of Sync.AI Services; (v) or any other matter
                                    relating to Sync.AI
                                    Services. Sync.AI shall not be liable for delay or failure in
                                    performance
                                    resulting from causes beyond Sync.AI's reasonable control.
                                </li>
                                <li>
                                    Applicable law may not allow the exclusion of certain
                                    warranties, so to that
                                    extent such exclusions may not apply.
                                </li>
                                <li>
                                    We also do not control what people and others users do or
                                    say, and we are not
                                    responsible for their (or your) actions or conduct (whether them
                                    online or
                                    offline) or content (including unlawful or objectionable
                                    content). We also are not
                                    responsible for services and features offered by other people or
                                    companies, even
                                    if you access through our Sync.AI Services.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;CONFIDENTIALITY.</span>
                            <p className={`${styles.c1}`}>
                                You may have access to certain non-public or proprietary information and
                                materials
                                of Sync.AI, whether in tangible or intangible form <span className={styles.bold}>("Confidential
                                Information")</span>.
                                You shall take commercially reasonable measures to protect the Sync.AI’s
                                Confidential Information within your possession or control, from misuse
                                or
                                disclosure to a third party. You shall use the Sync.AI's Confidential
                                Information
                                solely for the purposes of performing under this Agreement. In the event
                                that you
                                are required to disclose Confidential Information of Sync.AI pursuant to
                                any Law,
                                regulation, or governmental or judicial order, you will (a) promptly
                                notify
                                Sync.AI in writing of such Law, regulation or order, (b) reasonably
                                cooperate with
                                Sync.AI in opposing such disclosure, (c) only disclose to the extent
                                required by
                                such Law, regulation or order (as the case may be).
                            </p>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;PRIVACY.</span>
                            <p className={`${styles.c1}`}>
                                Our privacy policy is available at <Link
                                href={"/privacy"}>http://www.sync.ai/privacy</Link>
                            </p>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;EXPORT LAWS.</span>
                            <p className={`${styles.c1}`}>
                                You agree to comply fully with all applicable export laws and
                                regulations to ensure
                                that
                                neither the Sync.AI Services nor any technical data related thereto are
                                exported or
                                re-exported directly or indirectly in violation of, or used for any
                                purposes
                                prohibited
                                by, such laws and regulations.
                            </p>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;THIRD PARTY SERVICES.</span>
                            <p className={`${styles.c1}`}>
                                This Agreement applies only to your use of the Sync.AI Services. Sync.AI
                                has not
                                reviewed, has no control over, nor does it endorse, screen, promote or
                                otherwise
                                assume
                                any responsibility for the content of third party websites or
                                applications ("Third
                                Party
                                Services"), or to the information, advertisements, services, practices
                                or other
                                material
                                contained on, or accessible through Third Party Services, including
                                without limitation
                                third party websites to which hyperlinks are contained in the Sync.AI
                                Services. To the
                                maximum extent permitted by law, by using the Sync.AI Services you
                                hereby agree that
                                Sync.AI shall not be responsible for any damage, claim or liability in
                                connection with
                                your use of any third party sites, including, without limitation, third
                                party sites to
                                which you have arrived via the Sync.AI Services. Accordingly, we
                                strongly encourage
                                you
                                to become familiar with the terms of use, privacy policies and practices
                                of any such
                                Third Party Services. The use of Third Party Services is at your sole
                                risk.
                            </p>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;GOOGLE PRIVACY POLICY AND YOUTUBE API.</span>
                            <p className={`${styles.c1}`}>
                                Please note that the Sync.AI Services may use the YouTube API to provide
                                you with Youtube videos in the People & Company Insights feature.
                                By using this integration you are agreeing to be bound by the YouTube Terms of Service
                                (<Link href={"https://www.youtube.com/t/terms"}>https://www.youtube.com/t/terms</Link>),
                                Privacy Policy (<Link href={"http://www.google.com/policies/privacy"}>http://www.google.com/policies/privacy</Link>),
                                and API Services Terms of Service
                                (<Link href={"https://developers.google.com/youtube/terms/api-services-terms-of-service"}>https://developers.google.com/youtube/terms/api-services-terms-of-service</Link>).
                            </p>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;INDEMNIFICATION.</span>
                            <p className={`${styles.c1}`}>
                                To the maximum extent permitted by law, you agree to indemnify, defend,
                                and hold
                                Sync.AI (and its affiliated companies, contractors, employees, agents,
                                and suppliers
                                and
                                partners) harmless from any and all claims, suits, actions, liabilities,
                                losses,
                                costs,
                                damages, expenses, and any other liabilities, including without
                                limitation attorneys’
                                fees, arising out of or related in any manner to your violation of this
                                Agreement
                                and/or
                                your use or misuse of the Sync.AI Services, including, without
                                limitation, your access
                                to or use of the Sync.AI Services, any breach or alleged violation of
                                the rights of
                                any
                                other person or entity by you, including, without limitation, any
                                intellectual
                                property
                                right, publicity, confidentiality, property or privacy right and any
                                breach or alleged
                                violation by of this Agreement. Without derogating from or excusing your
                                obligations
                                under this section, we reserve the right (at your own expense), but are
                                not under any
                                obligation, to assume the exclusive defense and control of any matter
                                which is subject
                                to an indemnification by you if you choose not to defend or settle it.
                                You agree not
                                to
                                settle any matter subject to an indemnification by you without first
                                obtaining our
                                express approval.
                            </p>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;LIMITATION ON LIABILITY.</span>
                            <ol>
                                <li>
                                    YOU HEREBY EXPRESSLY AGREE, THAT TO THE FULLEST EXTENT NOT
                                    PROHIBITED BY THE
                                    APPLICABLE LAW, THAT SYNC.AI SHALL NOT BE LIABLE, IN ANY EVENT,
                                    FOR: (i) ANY
                                    INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY
                                    DAMAGES, UNDER ANY
                                    THEORY OF LIABILITY, INCLUDING BUT NOT LIMITED TO CONTRACT, TORT
                                    (INCLUDING
                                    NEGLIGENCE), DAMAGES, OR FOR LOSS OF DATA, PROFITS AND GOODWILL,
                                    ARISING OUT OF
                                    THE USE, MISUSE OR INABILITY TO USE THE SYNC.AI SERVICES, OR BY
                                    ANY FORM OF
                                    MALWARE, WHETHER OR NOT SYNC.AI WAS OR SHOULD HAVE BEEN AWARE OR
                                    ADVISED OF THE
                                    POSSIBILITY OF SUCH DAMAGES; AND (ii) IN ANY EVENT, THE
                                    COMAPNY’S TOTAL AGGREGATE
                                    LIABILITY FOR ALL DAMAGES AND LOSSES THAT ARISE UNDER OR IN
                                    CONNECTION WITH THIS
                                    AGREEMENT, OR THAT RESULT FROM YOUR USE OF OR INABILITY TO USE
                                    THE SYNC.AI
                                    SERVICES, SHALL NOT IN ANY CIRCUMSTANCE EXCEED THE TOTAL
                                    AMOUNTS, IF ANY, ACTUALLY
                                    PAID BY YOU TO SYNC.AI FOR USING THE SYNC.AI SERVICES WITHIN THE
                                    THREE (3) MONTHS
                                    PRECEDING THE DATE OF BRINGING A CLAIM, OR, IF NO SUCH AMOUNT
                                    WAS PAID, AN
                                    AGGREGATE AMOUNT OF ONE US DOLLAR ($1) OR THE MINIMUM AMOUNT
                                    PERMITTED BY LAW.
                                </li>
                                <li>
                                    THE LIMITATIONS AND DISCLAIMER IN THIS SECTION DO NOT PURPORT TO
                                    LIMIT
                                    LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE
                                    EXCLUDED UNDER
                                    APPLICABLE LAW. BECAUSE SOME STATES OR JURISDICTIONS DO NOT
                                    ALLOW THE EXCLUSION OF
                                    OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
                                    DAMAGES, IN SUCH
                                    STATES OR JURISDICTIONS, SYNC.AI’S LIABILITY SHALL BE LIMITED TO
                                    THE EXTENT
                                    PERMITTED BY LAW. THIS PROVISION SHALL HAVE NO EFFECT ON
                                    SYNC.AI’S CHOICE OF LAW
                                    PROVISION SET FORTH BELOW.
                                </li>
                                <li>
                                    Failure to assert, at any time, any right, or require
                                    performance in regard
                                    to this Agreement by or on behalf of Sync.AI shall not
                                    constitute concession,
                                    yield, waiver or relinquishment of any sort, and shall not limit
                                    Sync.AI's rights
                                    with respect to such breach or any subsequent breaches. A waiver
                                    of any breach of
                                    any provision of this Agreement shall not be construed as a
                                    continuing waiver of
                                    other breaches of the same or other provisions of this
                                    Agreement.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;PREMIUM ACCOUNTS</span>
                            <p className={styles.c1}>
                                Any use of any of our premium subscription offerings is bound by the
                                terms
                                of this Agreement in addition to the following specific terms:
                                <ul className={styles.disc}>
                                    <li>
                                        <p className={styles.c1}>
                                            Premium Accounts are billed in advance and are
                                            non-refundable. Your
                                            payment may be processed through a third party payment
                                            processing service,
                                            and additional terms may apply to such payments. We reserve
                                            the right to use
                                            other third party payment processing services for such
                                            purposes in the
                                            future. Unless otherwise stated in the subscription plan for
                                            your Premium
                                            Account, all amounts payable under this Agreement are
                                            non-refundable,
                                            non-cancellable, and exclusive of all sales, use,
                                            value-added, withholding,
                                            and other direct or indirect taxes, charges, levies and
                                            duties, including,
                                            in the following explicit cases: unavailability of Sync.AI
                                            Services,
                                            downgrade, unused Sync.AI Services, or when the
                                            functionality of Sync.AI
                                            Services is changed. For all versions of the Sync.AI
                                            Services, we reserve
                                            the right in the future to charge a fee for features and/or
                                            uses which are
                                            currently made available free of charge. Any failure to pay
                                            applicable
                                            charges may result in you not having access to some or all
                                            of the Sync.AI
                                            Services
                                        </p>
                                    </li>
                                    <li>
                                        <p className={styles.c1}>
                                            If you subscribe to our Premium Accounts, you select the
                                            number of users
                                            authorized to use your Premium Account. A user is identified
                                            by his e-mail
                                            address. You are responsible to ensure that the maximum user
                                            number for your
                                            Premium Account is not exceeded and that, if the maximum
                                            user number is
                                            exceeded, you should upgrade to a higher subscription. If
                                            you subscribe to
                                            Premium Accounts on behalf of an enterprise, Sync.AI
                                            Services may enable you
                                            define a name for your company’s site. You understand that
                                            Sync.AI can
                                            rename your company’s site, e.g., if you intentionally or
                                            unintentionally
                                            interfere with a trademark owner. Abuse or excessively
                                            frequent requests via
                                            a Premium Accounts may result in the temporary or permanent
                                            suspension of
                                            the user-account. Sync.AI determines abuse or excessive
                                            usage at its sole
                                            discretion.
                                        </p>
                                    </li>
                                    <li>
                                        <p className={styles.c1}>
                                            Sync.AI reserves the right to temporarily or permanently
                                            suspend
                                            user-account immediately if any of the terms under this
                                            Agreement are
                                            violated. If a user-account is to be suspended, Sync.AI will
                                            make a
                                            reasonable attempt by e-mail to warn the user-account owner
                                            prior to
                                            suspension.
                                        </p>
                                    </li>
                                    <li>
                                        <p className={styles.c1}>
                                            A Premium Account is always associated with a user-account.
                                            All
                                            communication will be directed to the e-mail address
                                            associated with that
                                            user-account. You are responsible to keep your user-account
                                            information
                                            up-to-date. Prices of Sync.AI Services are subject to
                                            change. If they
                                            change, the changes do not affect running subscriptions but
                                            will take effect
                                            only for any subsequent subscription period you choose to
                                            purchase. We
                                            reserve the right to change the prices and Premium Account
                                            at any time, at
                                            any time on ten (10) days’ notice. Such notice may be sent
                                            to you by email
                                            to your most recently provided email address or posted on
                                            Sync.AI's website
                                            or by any other manner chosen by Sync.AI in its commercially
                                            reasonable
                                            discretion. If you have purchased or obtained Sync.AI
                                            Services for a period
                                            of months or years, changes in prices and the subscription
                                            fees for the
                                            Premium Account shall be effective when the Sync.AI Services
                                            in question
                                            come up for renewal. In the abovementioned case, Sync.AI is
                                            not liable to
                                            you or to any third party for any price change.
                                        </p>
                                    </li>
                                </ul>
                            </p>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;APP STORES ADDITIONAL TERMS</span>
                            <ol>
                                <li>
                                    You acknowledge that this Agreement is between you and Sync.AI
                                    and not with
                                    Apple Inc. ("Apple") or Google Inc. ("Google"). The Google Play
                                    Store or the Apple
                                    App Store have their own terms and conditions to which you must
                                    agree to abide by
                                    before downloading Sync.AI Services from them. You agree to
                                    comply with, and your
                                    license to use Sync.AI Services is conditioned upon your
                                    compliance with, all
                                    applicable terms and conditions of the Google Play Store or the
                                    Apple App Store.
                                </li>
                                <li>
                                    Please note that the following terms apply in additional to all
                                    other
                                    provisions in this Agreement, if you downloaded Sync.AI Services
                                    to your mobile
                                    device from the Apple App Store or Google Play Store.
                                </li>
                                <li>
                                    You acknowledge and agree that:
                                    <ul>
                                        <li>
                                            <p className={styles.c1}>
                                                Apple has no obligation to furnish any maintenance
                                                and support services with
                                                respect to Sync.AI Services or handle any warranty
                                                claims.
                                            </p>
                                        </li>
                                        <li>
                                            <p className={styles.c1}>
                                                Apple is not responsible for addressing any claims
                                                you have relating to
                                                Sync.AI
                                                Services, including product liability claims,
                                                consumer protection claims,
                                                intellectual property infringement claims, or any
                                                claim that Sync.AI
                                                Services
                                                fails to conform to any applicable legal or
                                                regulatory requirement.
                                            </p>
                                        </li>
                                        <li>
                                            <p className={styles.c1}>
                                                Apple and Apple’s subsidiaries, are third party
                                                beneficiaries of this
                                                Agreement
                                                and that, upon your acceptance of this Agreement,
                                                Apple will have the right
                                                (and
                                                will be deemed to have accepted the right) to
                                                enforce this Agreement against
                                                you
                                                as a third party beneficiary thereof.
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    In the event of any failure of Sync.AI Services to conform to
                                    any applicable
                                    warranty, you may notify Apple, and Apple will refund the
                                    purchase price for
                                    Sync.AI Services to you (if applicable) and to the maximum
                                    extent permitted by
                                    applicable law, and Apple will have no other warranty obligation
                                    whatsoever with
                                    respect to Sync.AI Services. Any claims, losses, liabilities,
                                    damages, costs or
                                    expenses attributable to any failure to conform to any warranty
                                    will be the sole
                                    responsibility of Sync.AI, in accordance with the provisions of
                                    this Agreement.
                                </li>
                                <li>
                                    Apple shall not be responsible nor shall you refer to Apple any
                                    claim of any
                                    third party that Sync.AI Services or your possession and use of
                                    Sync.AI Services
                                    infringes that third party’s intellectual property rights.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;ASSIGNMENT OF RIGHTS, SEVERABILITY AND WAIVER.&nbsp;</span>
                            This Agreement, and any rights and licenses granted hereunder, may not be
                            transferred or assigned by you but may be assigned by Sync.AI without
                            restriction
                            or notification.
                        </li>
                        <li>
                             <span
                                 className={styles.bold}>&nbsp;MODIFICATIONS OF THE SYNC.AI SERVICES</span>
                            . To the maximum extent permitted by law, Sync.AI reserves the right at any
                            time to: (i)
                            change any information, specifications, features or functions of the Sync.AI
                            Services,
                            (ii) suspend or discontinue, temporarily or permanently, any or all of the
                            Sync.AI
                            Services features, or (iii) impose limits on certain features and Sync.AI
                            Services or
                            restrict access to parts or all of the Sync.AI Services, in each case with
                            or without
                            prior notice and without any liability to you or any third party. Sync.AI
                            may from time
                            to time update or revise this Agreement. If Sync.AI updates or revises this
                            Agreement,
                            Sync.AI will post the updated or revised Agreement on the website or by any
                            other manner
                            chosen by Sync.AI in its commercially reasonable discretion. Such change
                            will be
                            effective thirty (30) days following the foregoing notification thereof, and
                            your
                            continued use of the Sync.AI Services thereafter means that you accept those
                            changes to
                            the Agreement as updated or revised. You can view the most current Agreement
                            on our
                            website. It is your responsibility to review the Agreement periodically and
                            we encourage
                            you to do so.
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;AMENDMENTS TO THE AGREEMENT&nbsp;</span>
                            The Company may change the Agreement from time to time. We will notify
                            regarding
                            substantial changes of this Agreement on Sync.AI Services. Such substantial
                            changes will
                            take effect seven (7) days after such notice was provided on Sync.AI
                            Services.
                            Otherwise, all other changes to this Agreement are effective as of the
                            stated “Last
                            Updated” date and your continued use of the Sync.AI Services after the Last
                            Update date
                            will constitute acceptance of, and agreement to be bound by, those changes.
                            Please note
                            that in the event that the Agreement should be amended to comply with any
                            legal
                            requirements, the amendments may take effect even immediately, or as
                            required by the law
                            and without any prior notice.
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;TERM AND TERMINATION&nbsp;</span>
                            This Agreement is effective until terminated by Sync.AI or you. We reserve
                            the right, at
                            any time, to: (i) discontinue or modify any aspect of the Sync.AI Services;
                            and/or (ii)
                            terminate this Agreement and your use of the Sync.AI Services, if you engage
                            in any
                            conduct or activities that we determine, in our sole discretion, violate
                            this Agreement
                            or the rights of Sync.AI or any third party, or is otherwise inappropriate,
                            and, to the
                            maximum extent permitted by law, Sync.AI shall not be liable to you or any
                            third party
                            for any of the foregoing. If you object to any term or condition of this
                            Agreement or
                            any subsequent modifications thereto, or become dissatisfied with the
                            Sync.AI Services
                            in any way, your only recourse is to immediately discontinue use of the
                            Sync.AI
                            Services. Unless otherwise stated in this Agreement or required under
                            applicable law,
                            termination shall not give rise to any refund. Upon termination of this
                            Agreement, you
                            shall cease all use of the Sync.AI Services. This Section 20 and 7
                            (INTELLECTUAL
                            PROPERTY RIGHTS), 9 (CONFIDENTIALITY), 13 (INDEMNIFICATION), 14 (LIMITATION
                            OF
                            LIABILITY) and 21 (GENERAL), shall survive termination of this Agreement.
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;GENERAL.&nbsp;</span>
                            If you have any questions, do not hesitate to reach out to us at: <a
                            href={"mailto:support@sync.ai"} target={"_blank"}>support@sync.ai</a>.
                            This
                            Agreement, and any other legal notices published by us in connection
                            with the Sync.AI
                            Services, shall constitute the entire agreement between you and Sync.AI
                            concerning the
                            Sync.AI Services. In the event of a conflict between this Agreement and
                            any of the
                            foregoing, the terms of this Agreement shall prevail. No amendment to
                            this Agreement
                            will be binding unless in writing and signed or otherwise executed by
                            Sync.AI. If any
                            provision of this Agreement is deemed invalid by a court of competent
                            jurisdiction,
                            the
                            invalidity of such provision shall not affect the validity of the
                            remaining provisions
                            of this Agreement, which shall remain in full force and effect. No
                            waiver of any term
                            of
                            this Agreement shall be deemed a further or continuing waiver of such
                            term or any
                            other
                            term, and a party’s failure to assert any right or provision under this
                            Agreement
                            shall
                            not constitute a waiver of such right or provision.
                            <br/>
                            <br/>
                            This Agreement constitutes the entire agreement between you and Sync.AI
                            und replace
                            all
                            previous agreements under this title. There are no verbal subsidiary
                            agreements.
                            Notwithstanding anything to the contrary and to the fullest extent
                            permissible by law,
                            this Agreement, this Agreement, shall be governed by the laws of the
                            State of Israel
                            without regard to its conflict of laws rules. You and Sync.AI agree to
                            submit to the
                            personal and exclusive jurisdiction of the courts located in Tel Aviv,
                            Israel, to
                            resolve any legal matter arising from the Agreement. Notwithstanding the
                            foregoing,
                            Sync.AI may seek injunctive relief in any court worldwide of competent
                            jurisdiction.
                        </li>
                        <li>
                            <span className={styles.bold}>&nbsp;CONTACT US.&nbsp;</span>
                            If you have any other questions or queries please contact us by email in the
                            following
                            address: <a href={"mailto:support@sync.ai"}
                                        target={"_blank"}>support@sync.ai</a>.
                            Please feel free to reach out to us at any time. If you are
                            unsatisfied with our response, you can reach out to the applicable data
                            protection
                            supervisory authority.
                        </li>
                    </ol>
                </div>
            </div>
        </section>
    )
}
