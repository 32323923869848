export default [
    "By using any of the Sync.AI apps, products, software, or services made available by Sync.AI, including the Sync.AI Meetings app and the Sync.AI Calls app (collectively referred to as “Sync.AI Services”), provided by Sync.Me Ltd. (“Sync.AI” or “Company”), you agree to be bound by the following terms and conditions (“Agreement”).\n" +
        "\n" +
        "Sync.AI reserves the right to update and change the Agreement at any time without notice. You can review the most current version of the Agreement at: http://www.sync.ai/terms.\n" +
        "\n" +
        "Failure to exercise or enforce any right or provision of the Agreement shall not constitute a waiver of such right or provision. You agree that Sync.AI owns all legal rights and intellectual property rights in Sync.AI Services. You must not remove, modify or obscure any legal notices. You are not entitled to use Sync.AIs’ trademarks, trade names, brands, domain names, or other distinctive brand features.\n" +
        "\n" +
        "Violation of any of the terms below can result in the termination of your user-account or access to Sync.AI Services. Violation of any of the terms will also terminate your usage rights of Sync.AI Services.\n" +
        "\n" +
        "If a provision of this agreement is or becomes illegal, invalid or unenforceable in any jurisdiction, that shall not affect: (i) the validity or enforceability in that jurisdiction of any other provision of this agreement; or (ii) the validity or enforceability in other jurisdictions of that or any other provision of this agreement.\n" +
        "\n" +
        "This Agreement constitutes the entire agreement between you and Sync.AI und replace all previous agreements under this title. There are no verbal subsidiary agreements. This Agreement, and your relationship with Sync.AI under this Agreement, shall be governed by the laws of the State of Israel. You and Sync.AI agree to submit to the exclusive jurisdiction of the courts located in Tel Aviv, Israel, to resolve any legal matter arising from the Agreement.\n" +
        "\n" +
        "PROVISION OF SYNC.AI SERVICES BY SYNC.AI\n" +
        "\n" +
        "Your use of Sync.AI Services is at your sole risk. Sync.AI Services are provided on an “AS IS” and “AS AVAILABLE” basis.\n" +
        "\n" +
        "Sync.AI does not warrant that (i) Sync.AI Services will meet your requirements or expectations, (ii) Sync.AI Services will be delivered uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of Sync.AI Services will be accurate or reliable, (iv) any errors in Sync.AI Services will be corrected.\n" +
        "You understand and agree that Sync.AI shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to any loss of profit, loss of goodwill, loss of business reputation, loss of data, cost of procurement of substitute goods or Sync.AI Services, or other intangible loss, resulting from: (i) the use or the inability to use Sync.AI Services; (ii) any changes which Sync.AI may make to Sync.AI Services, or any permanent or temporary cessation in the provision of Sync.AI Services; (iii) unauthorized access to or alteration of your transmissions or data; (iv) the deletion of, corruption of, or failure to store, any content and other communications data maintained or transmitted by or through the use of Sync.AI Services; (v) or any other matter relating to Sync.AI Services.\n" +
        "You understand that Sync.AI can use third parties (e.g., hosting partners) to provide the necessary resources (e.g., hardware, software, networking, storage, etc.) to run Sync.AI Services.\n" +
        "Sync.AI may stop, remove, modify, or add (permanently or temporarily) Sync.AI Services (or features within Sync.AI Services) at Sync.AI’s sole discretion. Any new, changed, or removed features are subject to the Agreement. Continued use of Sync.AI Services after any such changes constitute your consent to the changes.\n" +
        "All content, visual interfaces, information, graphics, design, compilation, computer code, products, software, trademarks, service marks, trade names, and other intellectual property rights related thereto, including, but not limited to, the mobile device applications, and all other elements contained in Sync.AI Services (collectively, the “Materials”) are the property of Sync.AI or its subsidiaries or affiliated companies and/or third-party licensors. This Agreement does not convey to you any interest in or to the Materials, but only a limited, revocable right of use in accordance with this Agreement and you acknowledge and agree that except for such limited right, under no circumstances do you acquire any right, title or interest to any part of the Materials, or any related materials. If you send us or otherwise share with us any ideas, comments, or suggestions regarding the Materials, you acknowledge and agree that we may use such ideas, comments or suggestions in any manner, for any purpose, and without the need for any further compensation, approval or consent.\n" +
        "With respect to any open source or third-party code that may be incorporated in Sync.AI Services, such open source code is covered by the applicable open source or third-party license, if any, authorizing use of such code. Nothing in this Agreement limits your rights under, or grants you rights that supersede, the terms and conditions of any applicable end user license for such open source software.\n" +
        "Under no circumstances will Sync.AI or its affiliates be responsible or liable for any loss or damage resulting from your reliance on information or other content posted in Sync.AI Services, or transmitted to or by any users. Any advice, information or other content that may be posted in Sync.AI Services is entirely for entertainment purposes only. Sync.AI makes no representations or warranties and expressly disclaims any and all liability concerning any treatment, action by, or effect on any person following the information offered or provided within or through Sync.AI Services.\n" +
        "\n" +
        "LICENSE\n" +
        "Subject to the provisions of this Agreement, Sync.AI hereby grants you a non-exclusive, non-transferable, revocable license to use a compiled code copy of Sync.AI Services account on one mobile device, owned, rented or leased solely by you.\n" +
        "\n" +
        "You acknowledge that Sync.AI may from time to time issue upgraded versions of its software, and may automatically upgrade the version of such software that you are using on your device. You consent to such automatic upgrading on your mobile device, and agree that the terms and conditions of these Terms will apply to all such upgrades. You agree that Sync.AI will not be liable to you for any such modifications.\n" +
        "\n" +
        "The foregoing license grant under this Agreement is not a sale of Sync.AI Services or Sync.AI's software or any copy thereof and Sync.AI or its third party licensors or partners retain all right, title, and interest in the (and any copy thereof). Any attempt by you to transfer any of the rights, duties or obligations hereunder, except as expressly provided for in this Agreement, is void. Sync.AI reserves all rights not expressly granted under this Agreement.\n" +
        "\n" +
        "\n" +
        "USE OF SYNC.AI SERVICES BY YOU\n" +
        "You agree to use Sync.AI Services only for purposes that are permitted by (i) the Agreement and (ii) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions.\n" +
        "\n" +
        "Sync.AI may offer or require you to register for a user-account. You must ensure that all registration information you provide will always be valid, correct, and up to date. You are responsible for maintaining the security of your user-account and password. User-accounts registered by bots or other automated methods are not permitted. You agree that you will not impersonate another person.\n" +
        "\n" +
        "You agree that you will not engage in any activity that interferes with or disrupts Sync.AI Services (or the servers and networks which are connected to Sync.AI Services).\n" +
        "\n" +
        "You agree that you will not reproduce, duplicate, copy, sell, trade or resell Sync.AI Services for any purpose without the express written permission by Sync.AI.\n" +
        "\n" +
        "You agree that you are solely responsible for (and that Sync.AI has no responsibility to you or to any third party for) any breach of your obligations under the Agreement and for the consequences (including any loss or damage which Sync.AI may suffer) of any such breach.\n" +
        "\n" +
        "You agree to Sync.AI’s privacy policy, as available at http://www.sync.ai/privacy.\n" +
        "\n" +
        "Sync.AI reserves the right (but has no obligation) to pre-screen, review, flag, filter, modify, refuse or remove any or all content or user-account from Sync.AI Services at Sync.AI’s sole discretion. You understand that by using Sync.AI Services you may be exposed to content that you may find offensive, indecent or objectionable and that, in this respect, you use Sync.AI Services at your own risk.\n" +
        "\n" +
        "You agree that you will not post or upload information or material to Sync.AI Services, or use Sync.AI Services in connection with material, which:\n" +
        "\n" +
        "a reasonable person or user could deem to be: offensive, abusive, obscene, objectionable, defamatory, libelous, sexually explicit, threatening, advocating harassment or intimidation, racially or ethnically offensive or otherwise offensive to any group or individual, or promoting hatred or physical harm of any kind.\n" +
        "\n" +
        "promotes information that is false or misleading;\n" +
        "\n" +
        "promotes illegal activities;\n" +
        "\n" +
        "impersonates, or otherwise misrepresents affiliation, connection or association with any person or entity.\n" +
        "\n" +
        "\n" +
        "PREMIUM ACCOUNTS\n" +
        "Any use of any of our premium subscription offerings is bound by the terms of this agreement in addition to the following specific terms:\n" +
        "\n" +
        "Premium Accounts are billed in advance and are non-refundable. There will be no refunds also in the following explicit cases: unavailability of Sync.AI Services, downgrade, unused Sync.AI Services, or when the functionality of Sync.AI Services is changed.\n" +
        "\n" +
        "Abuse or excessively frequent requests via a Premium Accounts may result in the temporary or permanent suspension of the user-account. Sync.AI determines abuse or excessive usage at its sole discretion.\n" +
        "\n" +
        "If you subscribe to our Premium Accounts, you select the number of users authorized to use your Premium Account. A user is identified by his e-mail address. You are responsible to ensure that the maximum user number for your Premium Account is not exceeded and that, if the maximum user number is exceeded, you upgrade to a higher subscription. If you subscribe to Premium Accounts on behalf of an enterprise, Sync.AI Services may enable you define a name for your company’s site. You understand that Sync.AI can rename your company’s site, e.g., if you intentionally or unintentionally interfere with a trademark owner.\n" +
        "\n" +
        "Sync.AI reserves the right to temporarily or permanently suspend user-account immediately if any of these terms are violated. Sync.AI determines violation at its sole discretion. If a user-account is to be suspended, Sync.AI will make a reasonable attempt by e-mail to warn the user-account owner prior to suspension.\n" +
        "\n" +
        "A Premium Account is always associated with a user-account. All communication will be directed to the e-mail address associated with that user-account. You are responsible to keep your user-account information up-to-date. Prices of Sync.AI Services are subject to change. If they change, the changes do not affect running subscriptions but will take effect only for any subsequent subscription period you choose to purchase.\n" +
        "\n" +
        "Sync.AI is not liable to you or to any third party for any price change.\n" +
        "\n" +
        "\n" +
        "APP STORES ADDITIONAL TERMS\n" +
        'You acknowledge that this Agreement is between you and Sync.AI and not with Apple Inc. ("Apple") or Google Inc. ("Google"). The Google Play Store or the Apple App Store have their own terms and conditions to which you must agree to abide by before downloading Sync.AI Services from them. You agree to comply with, and your license to use Sync.AI Services is conditioned upon your compliance with, all applicable terms and conditions of the Google Play Store or the Apple App Store.\n' +
        "\n" +
        "Please note that the following terms apply in additional to all other provisions in this Agreement, if you downloaded Sync.AI Services to your mobile device from the Apple App Store or Google Play Store.\n" +
        "\n" +
        "You acknowledge and agree that:\n" +
        "\n" +
        "Apple has no obligation to furnish any maintenance and support services with respect to Sync.AI Services or handle any warranty claims.\n" +
        "\n" +
        "Apple is not responsible for addressing any claims you have relating to Sync.AI Services, including product liability claims, consumer protection claims, intellectual property infringement claims, or any claim that Sync.AI Services fails to conform to any applicable legal or regulatory requirement.\n" +
        "\n" +
        "Apple and Apple’s subsidiaries, are third party beneficiaries of this Agreement and that, upon your acceptance of this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this Agreement against you as a third party beneficiary thereof.\n" +
        "\n" +
        "In the event of any failure of Sync.AI Services to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for Sync.AI Services to you (if applicable) and to the maximum extent permitted by applicable law, and Apple will have no other warranty obligation whatsoever with respect to Sync.AI Services. Any claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Sync.AI, in accordance with the provisions of this Agreement.\n" +
        "\n" +
        "Apple shall not be responsible nor shall you refer to Apple any claim of any third party that Sync.AI Services or your possession and use of Sync.AI Services infringes that third party’s intellectual property rights.\n" +
        "\n" +
        "\n" +
        "GENERAL\n" +
        "If you have any questions, don’t hesitate to reach out to us at: support@sync.ai\n" +
        "\n",
    "If you have any other questions or queries please contact us by email in the following address:\n" +
        "privacy@sync.ai, or by sending a letter to:\n" +
        "\n" +
        "Sync.Me LTD.\n" +
        "Attn: Data Protection Officer\n" +
        "Ze'ev Jabotinsky St 2, Ramat Gan, Israel\n" +
        "We will make an effort to reply within a reasonable timeframe.\n" +
        "\n" +
        "Please feel free to reach out to us at any time. If you are unsatisfied with our response, you can reach out to the applicable data protection supervisory authority.\n" +
        "\n",
]
